@import 'styles/mixins';

$btn-xxs: 1.2rem; // 18px
$btn-xs: 1.5rem; // 24px
$btn-sm: 1.75rem; // 28px
$btn-md: 2rem; // 32px
$btn-lg: 2.5; // 40px

// ---------------------------------------------------------------------
// Default
// ---------------------------------------------------------------------
:global(.ant-btn-loading) {
  &::before {
    display: none;
  }
}

a.default,
.default {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: $grid-1;
  flex-shrink: 0;
  flex-grow: 0;
  height: $btn-sm;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: $grid-2;
  padding-right: $grid-2;
  border-radius: $border-radius;
  background: none;
  border: 1px solid currentColor;
  outline: none; // make sure to create your own focus styling
  box-shadow: none;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  font-stretch: 100%;
  font-style: normal;
  letter-spacing: normal;
  text-decoration: none;
  white-space: initial;
  cursor: pointer;
  @include transition(all);
}
.default.sizeXS {
  column-gap: 3px;
  height: $btn-xxs;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
}
.default.sizeXS {
  column-gap: 4px;
  height: $btn-xs;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 12px;
}

.default.small {
  height: $btn-sm;
}

.default.sizeMd {
  height: $btn-md;
}

.default.sizeLg {
  height: $btn-lg;
}
.default.sizeXl {
  height: 32px;
}
.default.sizeXXl {
  height: 34px;

}
.default.disabled,
.default:disabled {
  color: $gray-300;
}

// ---------------------------------------------------------------------
// Icon
// ---------------------------------------------------------------------
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  height: $btn-sm;
  width: $btn-sm;
  padding-left: 0;
  padding-right: 0;
  border-radius: $border-radius;
}

.icon.sizeXXS {
  flex-grow: 0;
  flex-shrink: 0;
  height: $btn-xxs;
  width: $btn-xxs;
}
.icon.sizeXS {
  flex-grow: 0;
  flex-shrink: 0;
  height: $btn-xs;
  width: $btn-xs;
}
.icon.sizeMd {
  flex-grow: 0;
  flex-shrink: 0;
  height: $btn-md;
  width: $btn-md;
}
.icon.sizeLg {
  flex-grow: 0;
  flex-shrink: 0;
  height: $btn-lg;
  width: $btn-lg;
}
.icon.circle {
  border-radius: 50%;
}

// ---------------------------------------------------------------------
// Primary
// ---------------------------------------------------------------------
.isPrimary:disabled {
  background-color: $gray-500;
  border: 1px solid $gray-500;
  cursor: not-allowed;
}

.isPrimary.isDark {
  background-color: $gray-700;
  border: 1px solid $gray-700;
  color: $gray-200;
}
.isPrimary.isDark:hover {
  background-color: $gray-600;
  border: 1px solid $gray-600;
  color: $white;
}
.isPrimary.isDark:focus {
  background-color: $gray-600;
  border: 1px solid $gray-600;
  outline: 2px solid $blue-400;
  color: $white;
}

.isPrimary.isSidebar {
  background-color: $gray-900;
  border: 1px solid $orange;
  color: $gray-200;
}
.isPrimary.isSidebar:hover {
  background-color: $gray-900;
  border: 2px solid $orange;
  color: $gray-400;
}
.isPrimary.isSidebar:focus {
  background-color: $gray-900;
  border: 2px solid $orange;
  color: $gray-400;
}

.isPrimary.isLight {
  background-color: $blue-400;
  border: 1px solid $blue-400;
  color: $white;
}
.isPrimary.isLight:hover {
  background-color: $blue-400;
}
.isPrimary.isLight:focus {
  background-color: $blue-400;
  border: 2px solid $white;
}
.isPrimary.isLight.disabled,
.isPrimary.isLight:disabled {
  border: 1px solid $gray-400;
  background-color: $gray-400;
  color: $gray-500;
}

.isPrimary.isDanger {
  background-color: $red-400;
  border: 1px solid $red-400;
  color: $white;
}
.isPrimary.isDanger:hover {
  background-color: $red-500;
  border: 1px solid $red-400;
  color: $white;
}
.isPrimary.isDanger:focus {
  background-color: $red-500;
  border: 2px solid $red-400;
  color: $white;
}

.isPrimary.isDangerBorderless {
  background-color: transparent;
  border: 1px solid transparent;
  color: $red-400;
}
.isPrimary.isDangerBorderless:hover {
  background-color: transparent;
  border: 1px solid $red-400;
  color: $red-400;
}
.isPrimary.isDangerBorderless:focus {
  background-color: transparent;
  border: 2px solid $red-400;
  color: $red-400;
}

.isPrimary.isTransparent {
  background-color: transparent;
  border: 1px solid transparent;
  color: $blue-400;
}
.isPrimary.isTransparent:hover {
  background-color: transparent;
  border: 1px solid $blue-400;
  color: $blue-400;
}
.isPrimary.isTransparent:focus {
  background-color: transparent;
  border: 2px solid $blue-400;
  color: $blue-400;
}

// ---------------------------------------------------------------------
// Secondary
// ---------------------------------------------------------------------
.isSecondary.isDark {
  color: $gray-200;
  background-color: rgba($gray-600, 0.6);
  backdrop-filter: blur($blur-radius-base);
  border: 1px solid rgba($gray-500, 0.6);
  box-shadow: $elevation-300;
  transition: all 150ms ease;
  cursor: pointer;
}
.isSecondary.isDark:hover {
  background-color: rgba($gray-500, 0.6);
  border: 1px solid $gray-400;
  color: #fff;
  transition: all 150ms ease;
}
.isSecondary.isDark:focus {
  background-color: rgba($gray-500, 0.6);
  border: 1px solid rgba($gray-500, 0.6);
  outline: 1px solid $blue-400;
  color: $white;
}
.isSecondary.isDark.disabled,
.isSecondary.isDark:disabled {
  opacity: 0.8;
}

.isSecondary.isSidebar {
  background-color: $gray-700;
  border: 1px solid $gray-200;
  color: $gray-300;
}
.isSecondary.isSidebar:hover {
  background-color: $gray-900;
  border: 1px solid $gray-500;
  color: $gray-300;
}
.isSecondary.isSidebar:focus {
  background-color: $gray-900;
  border: 2px solid $gray-500;
  color: $gray-300;
}
.isSecondary.isSidebar.disabled,
.isSecondary.isSidebar:disabled {
  background-color: $gray-500;
  border: 1px solid $gray-500;
}

.isSecondary.isLight {
  background-color: transparent;
  border: 1px solid currentColor;
  color: $gray-200;
}
.isSecondary.isLight:hover {
  background-color: transparent;
  border: 1px solid currentColor;
  color: $white;
}
.isSecondary.isLight:focus {
  background-color: transparent;
  border: 2px solid currentColor;
  color: $white;
}
.isSecondary.isLight.disabled,
.isSecondary.isLight:disabled {
  background-color: transparent;
  border: 1px solid currentColor;
}

// ---------------------------------------------------------------------
// Simple
// ---------------------------------------------------------------------
a.isSimple,
.isSimple {
  height: auto;
  padding: 0;
  background: none;
  border: none;
  outline: none; // make sure to create your own focus styling
  box-shadow: none;
  font-size: inherit;
  text-align: left;
  text-transform: inherit;
  vertical-align: text-top;
  overflow: hidden;
  color: $blue-300;
  @include transition(all);
  cursor: pointer;
}
.isSimple:hover {
  background-color: transparent;
  color: $blue-200;
}
.isSimple:focus {
  background-color: transparent;
  color: $blue-200;
}
.isSimple.disabled,
.isSimple:disabled {
  background-color: transparent;
  color: $gray-600;
}
