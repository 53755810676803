@import 'styles/mixins.scss';

.root {
  :global .ant-alert {
    padding: 8px 15px 8px 35px;
    border-radius: 8px;
    border: none;
    background: $gray-900;
    border: 1px solid $gray-800;
  }

  :global .ant-alert-icon {
    font-size: 15px;
    left: 10px;
  }

  :global .ant-alert-message {
    display: inline-block;
    transform: translateY(0.5px); // Center vertically relative to icon
  }

  :global(.ant-alert-warning) {
    background: $gray-900;

    :global(i.anticon) {
      color: $yellow-300;
    }

    :global(.ant-alert-message) {
      color: $yellow-100;
    }
  }

  :global(.ant-alert-success) {
    background: $gray-900;

    :global(i.anticon) {
      color: $green-300;
    }

    :global(.ant-alert-message) {
      color: $green-300;
    }
  }

  :global(.ant-alert-error) {
    background: $gray-900;

    :global(i.anticon) {
      color: $red-500;
    }

    :global(.ant-alert-message) {
      color: $red-300;
    }
  }

  :global(.ant-alert-info) {
    background: $gray-200;

    :global(i.anticon) {
      color: $gray-400;
    }

    :global(.ant-alert-message) {
      color: $purple-300;
    }
  }
}
