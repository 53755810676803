@import 'styles/mixins.scss';

.root {
  background-color: $gray-800;
  border: 1px solid $gray-700;
  border-radius: 12px;
  color: $gray-300;
  max-width: 458px;
  width: 100%;

  :global .ant-collapse-item {
    .ant-collapse-content {
      background-color: $gray-700;
      border-top: 1px solid $gray-700;
      color: $gray-200;

      .ant-collapse-content-box {
        padding: 14px 19px;
      }
    }

    .ant-collapse-header {
      @include Body1;
      padding-left: 48px;
      padding-bottom: 19px;
      padding-right: 14px;
      padding-top: 19px;
      color: $gray-200;

      .anticon {
        color: $gray-200;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-700;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
