@import 'styles/mixins.scss';

.wrapper {
  flex-grow: 1;
  position: relative;

  &:not(:last-of-type) {
    padding-bottom: $grid-4;
  }
}

.alert {
  margin-bottom: $grid-1;
}

.list {
  @include reset-list;
}

.item {
  border-bottom: 1px solid $gray-700;
  display: flex;
  position: relative;

  @include query(mobile) {
    align-items: flex-start;
  }

  @include query(desktop) {
    align-items: center;
  }
}

.left {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  column-gap: 8px;
  padding: $grid-1 0;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 8px;
  overflow: hidden;
}

.label {
  flex-grow: 1;

  @include query(mobile) {
    margin-bottom: $grid-1;
  }
}

.remove {
  cursor: pointer;
  outline: none; // make sure to create your own focus styling
  padding: 0;
  background: none;
  border: none;
  text-align: left;
  @include square(40px);
  @include icon-x;

  margin-left: $grid-2;
}

.right {
  width: 56px;
}

.icon {
  @include query(mobile) {
    margin: 12px 6px 0 ($grid-3 + 2px);
  }

  @include query(desktop) {
    margin-left: $grid-4;
  }
}
