@import 'styles/mixins.scss';

.root {
  :global .ant-modal-body {
    padding-bottom: $grid-4;
  }
}

.iframeContainer {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe {
  @include absolute-fill;

  border: none;
}
