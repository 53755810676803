@import 'styles/mixins.scss';

// Content
// ======================

.root {
  @include page-root;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.root::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.content {
  z-index: 10;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

// Title
// ======================

.title {
  margin-bottom: $grid-2;
  margin-top: 50px;
  font-size: 32px;
}

// Help content
// ======================

.accordion {
  margin: 0 auto;
  text-align: left;
}

.helpSectionTitle {
  font-weight: 700;
  margin-bottom: 1em;

  &:not(:first-child) {
    margin-top: 1em;
  }
}

.link,
a.link {
  display: inline-flex;
  height: auto;
  color: $blue-300;
  transition: all ease 150ms;
  cursor: pointer;

  @include button-focus-hover {
    color: $blue-200;
    text-decoration: none;
    transition: all ease 150ms;
  }
}

.introContent {
  margin-bottom: 20px;
  margin-top: 20px;
  color: $gray-300;
  font-weight: 500;
}

// CTA
// ======================

.cta {
  padding: 16px;
  margin-bottom: 50px;
  margin-top: 60px;
  border-radius: 12px;
  background-color: $gray-800;
  border: 1px solid $gray-700;
}

// Gif
// ======================

.dinoGif {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px;
  mix-blend-mode: multiply;
  opacity: 0.4;
}
