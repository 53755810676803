@import 'styles/mixins.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: $gray-700;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: $gray-900;
}
