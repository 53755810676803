@import 'styles/mixins.scss';

a:link {
  color: $blue-300;
  font-weight: 500;
}
a:hover {
  color: $blue-200 !important;
}

// Layout
// =================================

.layout {
  display: flex;
  height: 100%;
  width: 100%;
}

.layoutCenter {
  max-width: 400px;
  margin: 0 auto;
}

.layoutLeft {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  width: 400px;
  // background-image: url('../../../assets/login-sidebar-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $gray-900;
}

.layoutRight {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  width: 100%;
  background-color: $midnight-900;
  overflow: hidden;
}

// Forms
// =================================

.header {
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 24px;
}

.lead {
  padding-top: 64px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 20px;
}

.content {
  padding-left: $grid-3;
  padding-right: $grid-3;
  padding-bottom: $grid-3;

  > p:not(:first-of-type) {
    margin: $grid-3 0 0;
  }
}

.form {
  padding-left: 24px;
  padding-right: 24px;
}

.buttonMicrosoft {
  margin-top: $grid-2;
  svg {
    transform: scale(calc(18 / 21));
  }
}

.buttonSAML {
  margin-top: $grid-2;
}

.buttonSignIn {
  width: 100%;
}

.input {
  margin-bottom: $grid-1;
  text-align: left;
  width: 100%;
}

// Helper Content
// =================================

.help {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-top: 8px;
  padding-left: 24px;
  padding-right: 24px;
}

.demoActions {
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;
  padding: 16px;
  // margin-left: 24px;
  // margin-right: 24px;
  background-color: $midnight-900;
  border-radius: 8px;
}

.demoHeader {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}

.demoSummary {
  padding-bottom: 4px;
  color: $gray-300;
}

// Login Video
// =================================

.loginVideo {
  object-fit: cover;
  height: 100%;
  width: 100%;
  mix-blend-mode: difference;
  opacity: 0.4;
}

// Reset Password
// =================================
.buttonBack {
  display: block;
  margin-top: $grid-5;

  > span {
    text-decoration: underline;
  }

  @include button-focus-hover {
    color: $yellow-500;
  }
}

.successAlert {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;
  padding: 16px;
  background-color: $midnight-900;
  border-radius: 8px;
}

.links {
  margin-top: $grid-2;

  > button {
    margin-top: $grid-2;
  }
}

// Misc
// =================================

.beforeButton {
  display: block;
  margin-bottom: $grid-3;
}

.buttonReset {
  margin-top: $grid-1;
}

.list {
  margin: $grid-1 0 $grid-3 $grid-3;
  padding-left: 0;

  li {
    margin-bottom: $grid-1;
  }
}

.signInWithDifferentProvider {
  margin-bottom: $grid-4;
}

a.buttonContactLink,
.buttonContactLink > span {
  text-decoration: underline;

  @include button-focus-hover {
    text-decoration: underline;
  }
}

.buttonContactLink {
  margin-bottom: $grid-4;
  margin-top: $grid-6;

  @include button-focus-hover {
    color: $yellow-500;
  }
}

a.buttonLink,
.buttonLink > span {
  display: block;
  text-decoration: underline;

  @include button-focus-hover {
    color: $yellow-500;
    text-decoration: underline;
  }
}

.buttonLink {
  display: block;

  &:not(:first-child) {
    margin-top: $grid-1;
  }
}

@media (max-width: 400px) {
  .buttonContactLink {
    margin-top: $grid-4;
  }

  .content {
    padding: $grid-4 $grid-5 $grid-5;
  }

  .root {
    padding-top: $grid-5;
  }
}

@media (max-width: 320px) {
  .content {
    padding: $grid-3 $grid-4 $grid-4;
  }
}
