@import 'styles/mixins.scss';

.label {
  color: $gray-300;
  font-weight: 500;
  letter-spacing: normal;
}

.medium {
  $local-spacing: 8px;

  &.left {
    font-size: $font-2;
    margin-right: $local-spacing;
  }

  &.top {
    margin-bottom: $local-spacing;
  }
}

.small {
  $local-spacing: 4px;

  &.left {
    margin-right: $local-spacing;
  }

  &.top {
    margin-bottom: $local-spacing;
  }
}

.xs {
  $local-spacing: 2px;

  &.left {
    margin-right: $local-spacing;
  }

  &.top {
    margin-bottom: $local-spacing;
  }
}
