@import 'styles/mixins.scss';

@mixin local-select-focus-hover($color) {
  .select {
    :global(.ant-select-selection):focus,
    :global(.ant-select-selection):hover {
      border-color: $color;

      :global .ant-select-selection {
        border-color: $color;
      }
    }
  }
}

.select {
  min-width: 140px;
  width: initial;

  :global .ant-select-selection {
    @include transition(all);
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid $gray-600;
    box-shadow: none;
    // min-height is required for selects with `multiple` or `tags` mode property
    min-height: 32px;
  }

  :global
    .ant-select-selection--multiple
    .ant-select-selection__rendered
    > ul
    > li {
    margin-top: 4px;
  }

  :global .ant-select-selection__choice {
    @include center-content;
  }

  :global .ant-select-selection__choice__content {
    @include Body2;
  }

  :global .ant-select-selection__placeholder {
    color: $gray-300;
  }

  :global .ant-select-selection__rendered {
    line-height: 32px;
    margin-right: $grid-4;
  }

  :global .ant-select-arrow {
    margin-top: 0;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    transform-origin: 0;
    color: $gray-200;
  }
  :global .ant-select-selection-selected-value {
    color: $gray-200;
  }
  :global .ant-select-selection-selected-value .select-value-shifted {
    color: $gray-200;
  }

  &:global.ant-select-disabled {
    :global .ant-select-arrow {
      display: none;
    }

    :global .ant-select-selection {
      background-color: $gray-100;

      &:active,
      &:focus,
      &:hover {
        border-color: $blue-400;
      }
    }
  }
}

.wrapper {
  color: $gray-300;
  display: flex;
  @include local-select-focus-hover($gray-300);

  &.left {
    align-items: center;
  }

  &.top {
    align-items: flex-start;
    flex-direction: column;
  }
}

.dark {
  .select {
    :global .ant-select-arrow,
    :global .ant-select-selection-selected-value {
      color: $gray-200;
    }

    :global(.ant-select-selection):hover {
      background-color: $gray-600;
      border-color: $gray-600;
      box-shadow: 2px 2px 8px rgba($black, 0.2);
      transition: all 150ms ease;

      :global .ant-select-selection {
        border-color: $gray-600;
      }
    }

    :global(.ant-select-selection):focus {
      background-color: $gray-600;
      border-color: $blue-400;
      outline: 1px solid $blue-400;
      box-shadow: 2px 2px 8px rgba($black, 0.2);
      transition: all 150ms ease;

      :global .ant-select-selection {
        border-color: $blue-400;
      }
    }
  }
}

:global .ant-select-dropdown {
  background-color: $gray-800;
  border: 1px solid $gray-700;
}

:global .ant-select-dropdown-menu-item {
  color: $gray-300;
}
:global .ant-select-dropdown-menu-item:hover,
:global .ant-select-dropdown-menu-item:active,
:global .ant-select-dropdown-menu-item:focus,
:global .ant-select-dropdown-menu-item-selected,
:global .ant-select-dropdown-menu-item-active,
:global
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
:global .ant-select-dropdown-menu-item-selected,
:global
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
:global
  .ant-select-dropdown-menu-item:active:not(.ant-select-dropdown-menu-item-disabled),
:global
  .ant-select-dropdown-menu-item:focus:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: $gray-700;
  color: $gray-100;
}
:global .ant-select-dropdown-menu-item:disabled {
  color: $gray-500;
  cursor: not-allowed;
}
