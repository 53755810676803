@import 'styles/mixins.scss';

.root {
  @include center-content;

  flex-grow: 1;
  height: 100%;

  > * {
    @include square(45px);
    @include spin();
  }
}
