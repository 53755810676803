@import 'styles/mixins.scss';

$local-height: 32px;
$local-select-padding: 8px;
$local-width: 200px;

.arrow {
  @include square(24px);

  color: $gray-400;
  pointer-events: none;
  position: absolute;
  right: $local-select-padding;
  top: 4px;
  transform: rotateZ(90deg);
}

.input {
  @include placeholder($gray-500);
  @include Body1;
  @include transition(all);

  border-radius: 6px;
  display: block;
  max-width: 100%;
  padding: $grid-1;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }
}

.label {
  display: inline-block;
  width: $local-width;

  &.error {
    .input {
      border-color: $red-500;
    }
  }

  input.input {
    height: $local-height;
  }

  select.input {
    appearance: none;
    height: $local-height;
    padding-bottom: $local-select-padding;
    padding-top: $local-select-padding;

    &:not(:disabled) {
      cursor: pointer;
    }

    &:disabled {
      + .arrow {
        color: $gray-300;
      }
    }

    &::-ms-expand {
      display: none;
    }
  }

  textarea.input {
    min-height: 6em;
  }
}

.labelWrapper {
  height: $local-height;
  padding: $local-select-padding 0;
}

.left {
  align-items: center;
  display: flex;
  width: auto;

  .input {
    width: $local-width;
  }
}

.selectWrapper {
  position: relative;
}

.dark {
  .input {
    background-color: transparent;
    border: 1px solid $gray-600;
    color: $gray-300;

    &:active {
      box-shadow: none;
      border-color: $blue-400;
    }

    &:focus {
      box-shadow: none;
      border-color: $blue-400;
      outline: 1px solid $blue-400;
    }

    &:disabled {
      background-color: rgba($gray-600, 0.8);
      color: $gray-500;
      cursor: not-allowed;
    }
  }

  .labelWrapper {
    color: $gray-800;
  }
}

.light {
  .input {
    background-color: rgba($white, 0.1);
    backdrop-filter: blur($blur-radius-base);
    border: 1px solid $gray-400;
    color: $gray-100;

    &:focus {
      box-shadow: 2px 2px 8px rgba($white, 0.2);
    }

    &:hover {
      border-color: $gray-300;
    }

    &:disabled {
      background-color: $gray-600;
      color: $gray-400;
    }
  }

  &.fill {
    .input {
      background-color: $gray-100;
      border: 1px solid transparent;
      color: $gray-400;
    }
  }

  .labelWrapper {
    color: $gray-100;
  }
}

.block {
  width: 100%;
}
