@import 'styles/mixins.scss';

.container {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.homeButton {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 18px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border-radius: 8px;
  background: $midnight-900; /* fallback for old browsers */
  background: radial-gradient(at 100% 5%, $teal-400, transparent 50%),
    linear-gradient(45deg, $blue-700, $blue-500); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  > svg {
    color: $white;
  }
}

.hamburger {
  @include hamburger;
  cursor: pointer;
  outline: none; // make sure to create your own focus styling
  padding: 0;
  background: none;
  border: none;
  text-align: left;
  display: none;
  font-size: 0;

  @include query(mobile) {
    display: inline-block;
  }

  &.open {
    @include hamburger-open;
  }
}

.projectNav {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.projectNavItem {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  font-size: 14px;
}

.projectNavDivider {
  opacity: 0.4;
}
