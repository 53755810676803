@import 'styles/mixins.scss';

$header-height: 3rem; // 48px

.root {
  align-items: center;
  background: $midnight-900;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: $header-height;
  justify-content: space-between;
  left: 0;
  padding: 0 $grid-3;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6; // raise above interface

  + *:not(nav) {
    margin-top: $header-height;
  }

  + nav {
    height: calc(100vh - #{$header-height});
    top: $header-height;

    + * {
      margin-top: $header-height;
      max-height: calc(100% - #{$header-height});
    }
  }

  &.editor {
    @include query(mobile) {
      padding-left: 0;
      padding-right: $grid-1;
    }
  }
}

.button {
  cursor: pointer;
  outline: none; // make sure to create your own focus styling
  padding: 0;
  background: none;
  border: none;
  text-align: left;
  @include icon-button;
  @include icon-share;

  margin-left: $grid-4;
}

.content {
  align-items: center;
  display: flex;
}

.header {
  @include page-header-section;

  > button + button {
    margin-left: $grid-3;
  }
}

.heading {
  font-size: 0;
  user-select: none;
}

.logo {
  display: inline-block;
}
