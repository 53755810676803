@import 'styles/mixins.scss';

.title {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1;
  color: $gray-100;
}

.button {
  @include query(mobile) {
    width: 100%;
  }

  @include query(desktop) {
    width: 117px;
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  margin: $grid-3 0;
}

.input {
  flex-grow: 1;

  @include query(mobile) {
    margin-bottom: $grid-2;
  }

  @include query(desktop) {
    margin-right: $grid-2;
  }
}

.message {
  margin-top: $grid-1;
}
