@import 'styles/mixins.scss';

.menu {
  @include reset-list;
  min-width: 160px;
  width: 100%;
  background-color: $gray-700;
  border: none;
  color: $gray-200;

  :global .ant-dropdown-menu-item {
    padding: 0;
  }
}

.menu:global(.ant-menu-vertical) :global(.ant-menu-item) {
  overflow: visible;
}

.item:global(.ant-menu-item),
.item:global(.ant-menu-sub) > :global(.ant-menu-item),
.item:global(.ant-menu-submenu),
.item:global(.ant-menu-submenu) > :global(.ant-menu-submenu-title),
.item:global(.ant-menu-item-selected) {
  background-color: $white;
  color: currentColor;
  height: auto;
  line-height: 22px;
  margin: 0 !important;
  min-height: 35px;
  padding: 0;

  svg:first-child {
    position: relative;
    right: 8px;
    top: 2px;
  }

  &.danger {
    &,
    &:hover {
      color: $red-500;
    }
  }

  &:global(.ant-menu-submenu-active),
  &:hover {
    background-color: transparent;
    color: currentColor;
  }
}

// stylelint-disable-next-line no-duplicate-selectors
.item:global(.ant-menu-item),
.item:global(.ant-menu-sub) > :global(.ant-menu-item),
.item:global(.ant-menu-item-selected),
.item:global(.ant-menu-submenu) > :global(.ant-menu-submenu-title) {
  background-color: $gray-700;
  border-radius: $border-radius;

  > a {
    text-decoration: none;
  }

  > button {
    cursor: pointer;
    outline: none; // make sure to create your own focus styling
    padding: 0;
    background: none;
    border: none;
    text-align: left;
  }

  > a,
  > button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 2rem;
    width: 100%;
    padding-left: $grid-1;
    padding-right: $grid-1;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 6px;
    border: 1px solid transparent;
    color: inherit;
    font-weight: 400;
    @include transition(background-color);
    overflow: visible;
  }
  > a:hover,
  > button:hover {
    background-color: rgba($gray-200, 0.2);
    color: currentColor;
  }
  > a:focus,
  > button:focus {
    background-color: rgba($gray-200, 0.2);
    border: 1px solid $blue-400;
    outline: 1px solid $blue-400;
    color: currentColor;
  }
}

.item:global(.ant-menu-submenu) > :global(.ant-menu-submenu-title) {
  &,
  &:hover {
    > :global(.ant-menu-submenu-arrow) {
      &::after,
      &::before {
        background-color: rgba($gray-200, 0.2);
      }
    }
  }
}

.item:global(.ant-menu-item-disabled) {
  @include center-content;

  > span {
    color: $gray-500;
    padding: $grid-1 $grid-2;
    width: 100%;
  }
}

.item:not(:global(.ant-menu-horizontal)) {
  &:global(.ant-menu-item-selected) {
    background-color: transparent;
  }
}

.item :global a.ant-btn-button {
  display: block;
}
