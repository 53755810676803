@import 'styles/mixins.scss';

.button {
  min-width: 100px;
  text-transform: capitalize;
}

.input {
  display: block;
  margin-bottom: $grid-3;
  max-width: 276px;
  width: 100%;
}

.textarea {
  width: 100%;
}

.footerActions {
  display: flex;
  align-items: center;
  column-gap: 0;
  justify-content: flex-end;
}
