@import 'styles/mixins.scss';

.button {
  cursor: pointer;
  outline: none; // make sure to create your own focus styling
  padding: 0;
  background: none;
  border: none;
  text-align: left;
  @include icon-button;
  @include icon-share;

  display: none; // TODO: Remove when done with development
  margin-left: $grid-3;
  position: relative;
}

.icon {
  @include fill;
}
