@import '~antd/dist/antd.css';
@import 'styles/fonts.scss';
@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/variables.css';

html,
body,
:global(#root) {
  height: 100%;
}

// override antd body rule
html body {
  background-color: $gray-900;
  color: $white;
  overflow: hidden;
  font-family: $font-family-base;
}

:global #root {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

:global .ant-calendar-body {
  :global .ant-calendar-cell {
    &:global(.ant-calendar-selected-date) > div {
      background-color: $orange;
    }

    &:not(:global(.ant-calendar-selected-date)):global(.ant-calendar-today)
      > div {
      border-color: $orange;
      color: $orange;
    }

    &:not(:global(.ant-calendar-selected-date)) > div {
      &:focus,
      &:hover {
        background-color: rgba($orange, 0.2);
      }
    }
  }
}

:global .ant-calendar-footer,
:global .ant-calendar-header {
  a {
    color: inherit;
    text-decoration: none;

    &:focus,
    &:hover {
      color: $orange;
    }
  }
}

:global .ant-calendar-picker-container {
  color: $gray-600;
  z-index: 1000; // above modal dialog
}

// stylelint-disable-next-line selector-pseudo-element-colon-notation
:global .ant-popover::after {
  background: none;
}

// keep modals and popovers black-on-white
:global .ant-modal-wrap,
:global .ant-popover-content,
:global .ant-message,
:global .ant-dropdown .ant-menu-item,
:global .ant-dropdown-menu-submenu-title,
:global .ant-dropdown-menu-item,
:global .ant-menu-sub,
:global .ant-menu-submenu .ant-menu-item,
:global .rc-color-picker {
  color: $gray-900;
}

:global .ant-menu-item > a {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

:global(.ant-dropdown-menu),
:global(.ant-menu-submenu-popup),
:global(.ant-menu-submenu) > :global(.ant-menu) {
  border-radius: 0;
}

:global(.ant-menu-submenu) > :global(.ant-menu-submenu-title) {
  padding-right: $grid-5;

  &,
  &:hover {
    > :global(.ant-menu-submenu-arrow) {
      &::before,
      &::after {
        background: $gray-900;
      }
    }
  }
}

:global .ant-modal-confirm-body {
  :global .anticon-question-circle {
    display: none;
  }

  :global .ant-modal-confirm-title {
    @include modal-heading;
  }

  :global .ant-modal-confirm-content {
    margin-left: 0;
  }

  > .anticon {
    + .ant-modal-confirm-title {
      + .ant-modal-confirm-content {
        margin-left: 0;
      }
    }
  }
}

:global .ant-form-item-children {
  display: block;
}

:global .ant-form-item-label {
  line-height: inherit;

  label {
    @include label;
  }
}

:global .ant-radio-button-wrapper-checked {
  &:not(.ant-radio-button-wrapper-disabled) {
    background: $gray-200;

    &,
    &:active,
    &:hover {
      border-color: transparent;
      box-shadow: none;
      color: $gray-900;
    }
  }
}

:global .ant-input-number-handler-wrap {
  background: transparent;
}

:global .ant-tag {
  border-radius: 25px;
  height: 25px;
  line-height: 23px;
  margin-bottom: 1em;
  padding: 0 $grid-1;
}

:global(.ant-select-selection--multiple .ant-select-selection__choice) {
  border-radius: 6px;

  background: $gray-200;
  border: none;
  font-size: $font-2;
  padding: 1px 24px 0 $grid-1;
}

:global .ant-tooltip {
  pointer-events: none;
}

:global .ant-select-selection-selected-value {
  // This disables the default browser tooltip with title prop value (this cannot be changed with antdesign)
  // This doesn't produce any side effects since this element doesn't have any events.
  pointer-events: none;
}

:global(.ReactVirtualized__Grid):focus {
  // Removed focus outline in container div
  outline: none;
}

:global .ant-table {
  // Disable ant-design background color change on row hover
  :global .ant-table-tbody > tr:hover {
    &.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected),
    &:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
      > td {
        background: inherit;
      }
    }
  }
}

:global .ant-modal-mask {
  background-color: rgba($gray-900, 0.65);
}

:global .wrap-floor-plan {
  height: 100%;
}

:global .ant-btn-primary {
  background-color: $blue-400;
}
