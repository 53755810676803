@import 'styles/mixins.scss';

// Navbar Button
// ===========================================

.button {
  cursor: pointer;
  outline: none; // make sure to create your own focus styling
  padding: 0;
  background: none;
  border: none;
  text-align: left;
  @include icon-button;
  @include icon-share;

  margin-left: $grid-3;
  position: relative;
}

.badge {
  position: absolute;
  top: -2px;
  right: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  min-width: 18px;
  padding: 0 2px;
  border-radius: 6px;
  background-color: $red-400;
  color: $white;
  font-size: 12px;
}

// Notification List
// ===========================================

.popover {
  z-index: $z-index-notifications-popover;

  :global(.ant-popover-inner) {
    border-radius: 6px;
    width: 320px;
  }

  :global(.ant-popover-inner-content) {
    display: flex;
    flex-direction: column;
    max-height: 450px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0;
    padding-right: 0;
    color: $gray-200;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  :global(.ant-popover-inner-content)::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

.empty {
  @include align-horizontally;
  padding: $grid-2;
}

.emptyIcon {
  background-color: $gray-200;
}

// Notification Item
// ====================

.item {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 32px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
}

.iconItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  min-width: 24px;
  border-radius: 8px;
  background-color: $purple-700;
  color: $purple-200;
  overflow: hidden;

  &.iconError {
    background-color: $red-700;
    color: $red-200;
  }

  > * {
    color: currentColor;
  }
}

.label {
  @include ellipsis;
  flex-grow: 1;
}

.deleteButton {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(105%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 8px;
  color: $gray-200;
  background-color: rgba($gray-600, 0.6);
  backdrop-filter: blur($blur-radius-base);
  border: 1px solid rgba($gray-500, 0.6);
  outline: none; // make sure to create your own focus styling
  box-shadow: $elevation-300;
  text-align: left;
  transition: all 150ms ease;
  cursor: pointer;

  @include button-focus-hover {
    background-color: $red-500;
    border-color: $red-400;
    color: $white;
  }

  > svg {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.item:hover .deleteButton {
  visibility: visible;
  opacity: 1;
  transform: translate(-8px, -50%);
  transition: all 150ms ease;
}
