@font-face {
  /* REGULAR */
  font-family: 'Aeonik';
  src: url('https://densityco.github.io/assets/fonts/aeonik-regular.a0dd3136.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  /* MEDIUM */
  font-family: 'Aeonik';
  src: url('https://densityco.github.io/assets/fonts/aeonik-medium.0b69a6eb.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  /* BOLD */
  font-family: 'Aeonik';
  src: url('https://densityco.github.io/assets/fonts/aeonik-bold.07255666.woff2')
    format('woff2');
  font-weight: bold;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}