@import 'styles/mixins.scss';

.root {
  @include center-content;

  overflow: hidden;

  :global .ant-modal {
    margin: auto;
    max-width: 100vw;
    padding: 0;
    top: 0;
    color: $gray-300;
  }

  :global .ant-modal-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    max-height: 100vh;
    border-radius: 12px;
    background-color: $gray-800;
    border: 1px solid $gray-700;
    overflow-x: hidden;
    overflow-y: auto;
  }

  :global .ant-modal-header {
    border-bottom: 1px solid $gray-700;
    background-color: transparent;
    padding: $grid-2;
  }

  :global .ant-modal-title {
    @include TypographyStyle5;
    color: $gray-300;
  }

  :global .ant-modal-close-icon {
    display: none;
  }

  :global .ant-modal-close-x {
    @include icon-x;
  }

  :global .ant-modal-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: $grid-2;
    > * {
      flex-shrink: 0;
    }
  }

  :global .ant-modal-footer {
    border-top: 1px solid $gray-700;
    padding: $grid-2;
  }

  &.loading {
    :global .ant-modal-close-x,
    :global .ant-modal-footer {
      display: none;
    }
  }
}

.largeContainer {

  :global .ant-modal {
    padding: 0;
    top: 0;
    color: $gray-300;
    position: relative;
    width: 100vw;
    height: 80vh;
  }

  :global .ant-modal-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    max-height: 100vh;
    border-radius: 12px;
    background-color: $gray-800;
    border: 1px solid $gray-700;
    overflow-x: hidden;
    overflow-y: auto;
    width: 1300px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}