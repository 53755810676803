/* ---------------------------------------------------------------------
// Color
// --------------------------------------------------------------------- */

:root {
  --white: #ffffff;
  --black: #000000;
  --blue: #466aa1;
  --blue-000: hsl(219, 100%, 97%);
  --blue-100: hsl(221, 100%, 93%);
  --blue-200: hsl(220, 100%, 83%);
  --blue-300: hsl(220, 96%, 70%);
  --blue-400: hsl(215, 100%, 55%);
  --blue-500: hsl(220, 96%, 38%);
  --blue-600: hsl(220, 100%, 27%);
  --blue-700: hsl(220, 100%, 21%);
  --green-000: hsl(145, 68%, 96%);
  --green-100: hsl(158, 61%, 94%);
  --green-200: hsl(145, 70%, 81%);
  --green-300: hsl(145, 56%, 64%);
  --green-400: hsl(158, 100%, 37%);
  --green-500: hsl(158, 77%, 33%);
  --green-600: hsl(162, 77%, 22%);
  --green-700: hsl(155, 75%, 12%);
  --gray-000: hsl(212, 11%, 96%);
  --gray-100: hsl(212, 10%, 94%);
  --gray-200: hsl(212, 11%, 89%);
  --gray-300: hsl(212, 11%, 78%);
  --gray-400: hsl(212, 10%, 61%);
  --gray-500: hsl(212, 10%, 45%);
  --gray-600: hsl(212, 13%, 35%);
  --gray-700: hsl(212, 14%, 26%);
  --gray-800: hsl(212, 13%, 22%);
  --gray-900: hsl(212, 13%, 18%);
  --midnight-900: hsla(210, 10%, 12%, 1);
  --red-000: hsl(0, 100%, 97%);
  --red-100: hsl(0, 100%, 93%);
  --red-200: hsl(0, 100%, 85%);
  --red-300: hsl(356, 100%, 74%);
  --red-400: hsl(357, 100%, 64%);
  --red-500: hsl(356, 61%, 50%);
  --red-600: hsl(354, 62%, 33%);
  --red-700: hsl(348, 60%, 16%);
  --orange: hsl(17, 84%, 58%);
  --orange-000: hsl(25, 100%, 95%);
  --orange-100: hsl(22, 100%, 89%);
  --orange-200: hsl(25, 100%, 79%);
  --orange-300: hsl(22, 99%, 72%);
  --orange-400: hsl(17, 84%, 58%);
  --orange-500: hsl(17, 60%, 45%);
  --orange-600: hsl(17, 58%, 29%);
  --orange-700: hsl(36, 60%, 16%);
  --yellow-000: hsl(48, 83%, 98%);
  --yellow-100: hsl(48, 95%, 92%);
  --yellow-200: hsl(45, 100%, 83%);
  --yellow-300: hsl(43, 100%, 69%);
  --yellow-400: hsl(43, 89%, 55%);
  --yellow-500: hsl(42, 83%, 42%);
  --yellow-600: hsl(42, 87%, 28%);
  --yellow-700: hsl(42, 86%, 14%);
  --purple-000: hsl(270, 90%, 98%);
  --purple-100: hsl(270, 90%, 93%);
  --purple-200: hsl(270, 90%, 84%);
  --purple-300: hsl(270, 90%, 71%);
  --purple-400: hsl(270, 80%, 60%);
  --purple-500: hsl(270, 65%, 47%);
  --purple-600: hsl(270, 66%, 33%);
  --purple-700: hsl(270, 60%, 16%);
  --teal-000: hsl(184, 100%, 98%);
  --teal-100: hsl(184, 100%, 93%);
  --teal-200: hsl(184, 72%, 78%);
  --teal-300: hsl(184, 63%, 59%);
  --teal-400: hsl(184, 67%, 44%);
  --teal-500: hsl(184, 79%, 34%);
  --teal-600: hsl(184, 85%, 24%);
  --teal-700: hsl(184, 86%, 14%);
  --pink-000: hsl(340, 100%, 97%);
  --pink-100: hsl(340, 100%, 90%);
  --pink-200: hsl(340, 100%, 82%);
  --pink-300: hsl(340, 97%, 73%);
  --pink-400: hsl(340, 100%, 63%);
  --pink-500: hsl(340, 64%, 45%);
  --pink-600: hsl(340, 62%, 34%);
  --pink-700: hsl(340, 60%, 19%);
  --sienna-500: #d47d5b;
}


/* ---------------------------------------------------------------------
// Typography
// --------------------------------------------------------------------- */
:root {
  --font-1: 10px;
  --font-2: 13px;
  --font-3: 16px;
}


/* ---------------------------------------------------------------------
// Grid
// --------------------------------------------------------------------- */
:root {
  --grid-1: 10px;
  --grid-2: 16px;
  --grid-3: 20px;
  --grid-4: 30px;
  --grid-5: 40px;
  --grid-6: 50px;
  --grid-7: 70px;
}