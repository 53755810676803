@import 'styles/mixins.scss';

.buttonSpace {
  cursor: pointer;
  outline: none; // make sure to create your own focus styling
  padding: 0;
  background: none;
  border: none;
  text-align: left;
  text-decoration: underline;
}

.sceneCount {
  margin-bottom: 0;
}
