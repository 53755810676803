// ---------------------------------------------------------------------
// Color
// ---------------------------------------------------------------------
$white: #ffffff !default;
$black: #000000 !default;
$blue: #466aa1 !default;
$blue-000: hsl(219, 100%, 97%) !default;
$blue-100: hsl(221, 100%, 93%) !default;
$blue-200: hsl(220, 100%, 83%) !default;
$blue-300: hsl(220, 96%, 70%) !default;
$blue-400: hsl(215, 100%, 55%) !default;
$blue-500: hsl(220, 96%, 38%) !default;
$blue-600: hsl(220, 100%, 27%) !default;
$blue-700: hsl(220, 100%, 21%) !default;
$green-000: hsl(145, 68%, 96%) !default;
$green-100: hsl(158, 61%, 94%) !default;
$green-200: hsl(145, 70%, 81%) !default;
$green-300: hsl(145, 56%, 64%) !default;
$green-400: hsl(158, 100%, 37%) !default;
$green-500: hsl(158, 77%, 33%) !default;
$green-600: hsl(162, 77%, 22%) !default;
$green-700: hsl(155, 75%, 12%) !default;
$orange: hsl(17, 84%, 58%) !default;
$orange-000: hsl(25, 100%, 95%) !default;
$orange-100: hsl(22, 100%, 89%) !default;
$orange-200: hsl(25, 100%, 79%) !default;
$orange-300: hsl(22, 99%, 72%) !default;
$orange-400: hsl(17, 84%, 58%) !default;
$orange-500: hsl(17, 60%, 45%) !default;
$orange-600: hsl(17, 58%, 29%) !default;
$orange-700: hsl(36, 60%, 16%) !default;
$gray-000: hsl(212, 11%, 96%) !default;
$gray-100: hsl(212, 10%, 94%) !default;
$gray-200: hsl(212, 11%, 89%) !default;
$gray-300: hsl(212, 11%, 78%) !default;
$gray-400: hsl(212, 10%, 61%) !default;
$gray-500: hsl(212, 10%, 45%) !default;
$gray-600: hsl(212, 13%, 35%) !default;
$gray-700: hsl(212, 14%, 26%) !default;
$gray-800: hsl(212, 13%, 22%) !default;
$gray-900: hsl(212, 13%, 18%) !default;
$midnight-900: hsla(210, 10%, 12%, 1) !default;
$red-000: hsl(0, 100%, 97%) !default;
$red-100: hsl(0, 100%, 93%) !default;
$red-200: hsl(0, 100%, 85%) !default;
$red-300: hsl(356, 100%, 74%) !default;
$red-400: hsl(357, 100%, 64%) !default;
$red-500: hsl(356, 61%, 50%) !default;
$red-600: hsl(354, 62%, 33%) !default;
$red-700: hsl(348, 60%, 16%) !default;
$yellow-000: hsl(48, 83%, 98%) !default;
$yellow-100: hsl(48, 95%, 92%) !default;
$yellow-200: hsl(45, 100%, 83%) !default;
$yellow-300: hsl(43, 100%, 69%) !default;
$yellow-400: hsl(43, 89%, 55%) !default;
$yellow-500: hsl(42, 83%, 42%) !default;
$yellow-600: hsl(42, 87%, 28%) !default;
$yellow-700: hsl(42, 86%, 14%) !default;
$purple-000: hsl(270, 90%, 98%) !default;
$purple-100: hsl(270, 90%, 93%) !default;
$purple-200: hsl(270, 90%, 84%) !default;
$purple-300: hsl(270, 90%, 71%) !default;
$purple-400: hsl(270, 80%, 60%) !default;
$purple-500: hsl(270, 65%, 47%) !default;
$purple-600: hsl(270, 66%, 33%) !default;
$purple-700: hsl(270, 60%, 16%) !default;
$teal-000: hsl(184, 100%, 98%) !default;
$teal-100: hsl(184, 100%, 93%) !default;
$teal-200: hsl(184, 72%, 78%) !default;
$teal-300: hsl(184, 63%, 59%) !default;
$teal-400: hsl(184, 67%, 44%) !default;
$teal-500: hsl(184, 79%, 34%) !default;
$teal-600: hsl(184, 85%, 24%) !default;
$teal-700: hsl(184, 86%, 14%) !default;
$pink-000: hsl(340, 100%, 97%) !default;
$pink-100: hsl(340, 100%, 90%) !default;
$pink-200: hsl(340, 100%, 82%) !default;
$pink-300: hsl(340, 97%, 73%) !default;
$pink-400: hsl(340, 100%, 63%) !default;
$pink-500: hsl(340, 64%, 45%) !default;
$pink-600: hsl(340, 62%, 34%) !default;
$pink-700: hsl(340, 60%, 19%) !default;
$sienna-500: #d47d5b !default;

// ---------------------------------------------------------------------
// Typography
// ---------------------------------------------------------------------

$font-1: 12px !default;
$font-2: 14px !default;
$font-3: 16px !default;

$font-family-base: 'Aeonik', 'Helvetica', 'Arial', sans-serif; /* Aeonik Font Stack */

// ---------------------------------------------------------------------
// Grid
// ---------------------------------------------------------------------
$grid-1: 8px !default;
$grid-2: 16px !default;
$grid-3: 24px !default;
$grid-4: 32px !default;
$grid-5: 40px !default;
$grid-6: 48px !default;
$grid-7: 56px !default;
$grid-8: 72px !default;
$grid-9: 80px !default;
$grid-10: 88px !default;

// ---------------------------------------------------------------------
// Z-Index
// ---------------------------------------------------------------------
$z-index-navbar: 10;
$z-index-notifications-popover: 20; // above header
$z-index-popover: 30; // above .pano, annotations, and side panel

// ---------------------------------------------------------------------
// Elevation
// ---------------------------------------------------------------------
$elevation-300: 0px 4px 6px -1px rgba(39, 45, 51, 0.1),
  0px 2px 4px -1px rgba(39, 45, 51, 0.06);

// ---------------------------------------------------------------------
// Misc
// ---------------------------------------------------------------------
$duration: 0.3s !default;
$timing-function: cubic-bezier(0.645, 0.045, 0.355, 1) !default;
$border-radius: 6px;
$blur-radius-base: 12px;
$icon-size: 24px;
$size-xs: 24px;
$size-sm: 32px;
$size-md: 40px;
$size-lg: 48px;
