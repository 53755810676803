@import 'styles/mixins.scss';

.popover {
  z-index: $z-index-popover;

  &:global.ant-popover-placement-bottom,
  &:global.ant-popover-placement-bottomLeft,
  &:global.ant-popover-placement-bottomRight {
    padding-top: 12px;
  }

  :global .ant-popover-inner {
    border-radius: 8px;
    background-color: $gray-700;
    border: 1px solid $gray-600;
    box-shadow: 0 0 8px rgba($black, 0.25);
  }

  :global .ant-popover-inner-content {
    padding: 0.5rem;
    background-color: transparent;
  }

  :global .ant-popover-arrow {
    display: none;
  }
}
.popover.arrow {
  :global .ant-popover-arrow,
  :global .ant-popover-inner {
    box-shadow: 0 0 8px rgba($black, 0.25);
  }
}

.button {
  position: relative;
  padding: 0;
  background: none;
  border-radius: 6px;
  border: 1px solid transparent;
  @include icon-button;
  @include icon-ellipsis;
  @include square(16px);
  outline: none; // make sure to create your own focus styling
  text-align: left;
  color: $gray-400;
  @include transition(all);
  cursor: pointer;
}
.button:hover {
  background-color: rgba(255, 255, 255, 0.12);
  mix-blend-mode: screen;
  color: $white;
  @include transition(all);
}
.button:focus {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: $blue-400;
  outline: 1px solid $blue-400;
  mix-blend-mode: screen;
  color: $white;
  @include transition(all);
}

.button.active {
  background-color: rgba(255, 255, 255, 0.12);
  mix-blend-mode: screen;
  color: $white;
}
