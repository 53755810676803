@import 'styles/mixins.scss';

.root {
  @include fill;

  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  z-index: 5; // above basic UI, no modals though

  &.noHeader .panel {
    height: 100vh;
    top: 0;
  }

  &.open .panel {
    transform: translateX(0);
  }
}

.group {
  margin-bottom: $grid-6;

  .description {
    color: $gray-300;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: inherit;
  }

  .item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $grid-3;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .list {
    @include reset-list;

    margin-top: $grid-3;
  }

  .title {
    color: $white;
    font-size: inherit;
  }

  &:last-child {
    margin-bottom: $grid-3;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $grid-5;

  .button {
    $local-spacing: $grid-2;
    cursor: pointer;
    outline: none; // make sure to create your own focus styling
    padding: 0;
    background: none;
    border: none;
    text-align: left;
    @include transition(all);
    border-radius: 6px;

    color: $gray-300;
    height: 12px;
    padding: $local-spacing;
    position: relative;
    right: 0;
    top: 1px - ($local-spacing / 2);
    width: 12px;

    &::after,
    &::before {
      $local-height: 16px;

      background-color: currentColor;
      content: '';
      display: block;
      height: $local-height;
      left: calc(50% - .5px);
      position: absolute;
      top: calc(50% - #{$local-height / 2});
      width: 1px;
    }

    &::before {
      transform: rotateZ(45deg);
    }

    &::after {
      transform: rotateZ(135deg);
    }

    &:focus,
    &:hover {
      background-color: $gray-600;
      color: $white;
    }
  }

  .title {
    @include caps-heading;

    color: $gray-200;
  }
}

.key {
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.plain {
    color: $gray-400;
    line-height: 1;
    margin: 0 2px;
  }

  &.styled {
    border-radius: 6px;
    @include center-content;

    background-color: $gray-500;
    color: $gray-100;
    height: 25px;
    margin: 0 $grid-1;
    min-width: 25px;
    padding: 0 6px;
  }
}

.panel {
  @include transition(transform);

  background-color: $gray-800;
  height: calc(100vh - #{$grid-7});
  overflow: auto;
  pointer-events: initial;
  position: absolute;
  right: 0;
  top: $grid-7;
  transform: translateX(100%);
  width: 320px;
}

.panelInner {
  padding: $grid-4;
}

.sequence {
  align-items: center;
  display: flex;
}
