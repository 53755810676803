@import 'styles/mixins.scss';

.button {
  height: 2rem;
  width: 2rem;
}

.userMeta {
  padding-left: $grid-1;
  padding-right: $grid-1;
  padding-bottom: $grid-1;
  padding-top: $grid-1;
  margin-bottom: $grid-1;
  border-bottom: 1px solid rgba($gray-200, 0.2);
}

.userMetaHeader {
  font-size: 0.75rem; //12px
  line-height: 1;
  font-weight: 500;
  color: $gray-300;
  cursor: default;
}
